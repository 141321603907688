<template>
  <div class="main">
    <div class="main__background">
        <img class="main__background--img" src="../assets/textures/amarillo.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">ZAIRA BANDY</h2>
      <p class="main__header--subtitle">ACERCA DE MÍ</p>
    </div>
    <section class="main__first">
      <div class="main__first--cont">
        <img src="../assets/images/About/perfil1.jpg" alt="" class="main__first--img">
      </div>
      <div class="main__first--txt">
        <p>
          Soy <b>Zaira Bandy González,</b> una artista apasionada, soñadora y de sangre liviana. Ser conformista y apática no me define en lo absoluto, el esfuerzo y dedicación que he puesto en este proyecto ha sido toda una experiencia maravillosa, me da gusto compartirla contigo. 
        </p>
        <p>
          Me gusta ponerle especial atención a los detalles, soy observadora de mi entorno y de las personas que me rodean ya que me considero una mujer meticulosa, algo que llega a ser una herramienta valiosa a la hora de hacer mi trabajo.
        </p>
        <p>
          Además del arte soy amante de los atardeceres y tengo una fascinación por el corazón humano, metafórica y pictóricamente hablando.
        </p>
        <br>
        <b>MI PROCESO ARTÍSTICO</b>
        <p>
          Me dedico en cuerpo y alma al arte, como me gusta decir: Poniendo el corazón en el arte. 
        </p>
        <p>
          Me gusta crear y aprender de los artistas allegados a mi. Dedicada y conociendo la importancia de ser constante, para poder mejorar asiduamente, estoy entrenando la mano, no dejo de hacer lo que amo con esta motivación. Asimismo, una de mis prioridades es ver el trabajo de artistas pasados para cultivarme y comprender cómo es que ellos resolvieron y plasmaron sus ideas.
        </p>
        <p>
          Mi trabajo actualmente se enfoca en los retratos de mascotas, en pintar múltiples paisajes y en enseñar a través de mi canal de YouTube totorales de mis procesos para inspirar a más personas a que se entreguen a su creatividad, aprendan nuevas técnicas y que estén orgullosos de sus creaciones porque finalmente todos llevamos un artista dentro y a veces no lo dejamos libre.
        </p>
        <br>
        <b>MI MAYOR INSPIRACIÓN</b>
        <p>
          La vida misma, los enredos de la existencia, la locura de esta época en la que vivimos y los mismos obstáculos que he tenido que superar, así como el dolor y la soledad. También, mis personajes favoritos como Luis Buñuel, Vincent Van Gogh y Claude Monet. 
        </p>
        <p>
          Los temas que más me llaman la atención es la belleza del cuerpo humano y la complejidad del ser humano.
        </p>
      </div>
    </section>
    <section class="main__second">
      <div class="main__second--cont">
        <img src="" alt="" class="main__second--img">
      </div>
      <div class="main__second--txt">
        <p></p>
      </div>
    </section>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
export default {
  components: {
    Footer
  }
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
  z-index: 2000;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__first{
  display: flex;
}
.main__first--cont{
  padding: 0px 50px;
  margin-top: -100px !important;
  z-index: 200;
}
.main__first--img{
  width: 100%;
  height: 770px;
  object-fit: cover;
}
.main__first--txt{
  width: 35%;
  font-weight: 300;
  line-height: 22px;
  padding: 30px 100px;
}

.main__second{
  display: flex;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 60px;
  }
  .main__first {
    flex-direction: column;
    margin-top: 50px;
  }
  .main__first--txt {
    width: auto;
    padding: 50px;
  }
}
</style>
